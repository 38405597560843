import type {PolicyStateType} from "./index";
import type {DomainType} from "../flow/DomainType";
import type {AccountType} from "../flow/AccountType";

export const add = ({roles}: PolicyStateType, account: AccountType) => {
    return account.role === roles.admin;
};

export const manage = ({roles}: PolicyStateType, domain: DomainType) => {
    return [roles.admin, roles.manager].includes(domain.role);
};

export const remove = ({roles}: PolicyStateType, domain: DomainType) => {
    return domain.role === roles.admin;
};

export const subscription = ({roles}: PolicyStateType, domain: DomainType) => {
    return roles.admin === domain.role;
}
